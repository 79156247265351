import React from "react";
import "./home.css";

function Home() {
  return (
    <article className="home">
      <main>the home of mediocre developers like you</main>
      <footer>
        created by <a href="https://musumeche.com">Steven Musumeche</a>
      </footer>
    </article>
  );
}

export default Home;
